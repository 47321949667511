import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>Ús presentem un curs totalment renovat!</strong>
                </p>
                <p>
                    <strong>El curs online de psicotècnics més complet per a 2025</strong>. Un curs
                    extens i adaptat a totes les necessitats, on començareu des de zero i avançareu
                    fins estar completament preparats per afrontar qualsevol oposició de policia:{" "}
                    <strong>Mossos d'Esquadra</strong>, <strong>Guàrdia Urbana de Barcelona</strong>{" "}
                    o <strong>Policies Locals</strong>.
                </p>
                <p>
                    Totes les classes estan disponibles en format vídeo i exercicis pràctics per
                    assignatura, perquè pugueu posar-vos a prova i avançar al vostre ritme. Els
                    exercicis estan completament <strong>actualitzats i alineats</strong> amb els
                    exàmens oficials dels darrers anys.
                </p>
                <p>
                    Aquest curs està dissenyat per a <strong>tots els opositors</strong>,
                    independentment del nivell inicial. Començareu amb conceptes bàsics i
                    progressareu de manera gradual fins assolir un alt nivell de preparació.
                </p>
                <p>
                    <strong>Material nou cada setmana!</strong> És un curs dinàmic que evoluciona
                    constantment per garantir-vos la millor preparació. Cada matrícula inclou accés
                    durant 30 dies, el temps perfecte per assolir un bon nivell per l'oposició. Si
                    necessiteu més temps, podeu renovar fàcilment mes a mes.
                </p>
                <p>
                    <strong>Benvinguts al curs que s'adapta a tu i als teus horaris!</strong>{" "}
                    Comença avui mateix i fes el primer pas cap al teu futur com a policia.
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/h2h-pXRNDp8"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    ></iframe>
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Prepara't des de zero fins a l'excel·lència amb el curs online de psicotècnics més complet per superar qualsevol oposició policial!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            
            <div className="container lg:px-0 py-20">
                <Title>El Curso</Title>
                <Text>
                    Ús presentem un curs totalment renovat. El curs online de psicotècnics més
                    complet. És un curs llarg on començarem des de 0 i pujarem el nivell fins que
                    estigueu completament preparats per afrontar qualsevol oposició de policia, ja
                    sigui Mossos d'Esquadra, Guàrdia Urbana de Barcelona o Policies Locals. Totes
                    les classes estan penjades en forma de vídeos i exercicis per assignatura per
                    posar-vos a prova. Tots els exercicis estan actualitats i adaptats als exercicis
                    oficials que han sortit en els darrers anys. És per aquest motiu que està pensat
                    i dedicat a tots els opositors, independentment del nivell que tingueu en
                    aquests moments perquè començarem repassant coses bàsiques i anirem pujant el
                    nivell gradualment. És un curs molt viu on setmanalment us anirem penjant
                    material nou. Cada matrícula dura trenta dies que és el temps que calculem que
                    podeu assolir un bon nivell de cara a l'oposició. En cas de necessitar més temps
                    o voler repassar més, teniu la possibilitat de renovar mes a mes. Benvinguts al
                    curs que s'adapta a tu i als teus horaris!
                    <br />
                    <br />
                    <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/h2h-pXRNDp8"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        ></iframe>
                    </div>
                </Text>
            </div>

            
            <div className="container lg:px-0 py-20">
                <Title>Preview del curs</Title>
                <Text className="text-justify">Contingut del curs en format PDF.</Text>

                <div className="rounded p-5 border shadow-md mt-5">
                    <embed
                        src={require("@static/documents/products/25/presentacion-curso-psicos.pdf")}
                        type="application/pdf"
                        className=""
                        width="100%"
                        height="750px"
                    ></embed>
                </div>
            </div>

            
            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};

export default Product;*/
